import {
  FORM_UUID_EARACHE,
  FORM_UUID_IMPETIGO,
  FORM_UUID_INFECTED_INSECT_BITES,
  FORM_UUID_SHINGLES,
  FORM_UUID_SINUS_INFECTION,
  FORM_UUID_SORE_THROAT,
  FORM_UUID_URINARY_TRACT_INFECTION,
} from 'constants/app.constants';

export const r = {
  form: '/form',
  selectLocation: '/select-location',
  selectService: '/select-service',
  patientSignIn: '/patient-sign-in',
  continueAsGuest: '/continue-as-guest',
  formShingles: `/form/${FORM_UUID_SHINGLES}`,
  formImpetigo: `/form/${FORM_UUID_IMPETIGO}`,
  formInfectedInsectBites: `/form/${FORM_UUID_INFECTED_INSECT_BITES}`,
  formAcuteSoreThroat: `/form/${FORM_UUID_SORE_THROAT}`,
  formAcuteSinusitis: `/form/${FORM_UUID_SINUS_INFECTION}`,
  formAcuteOtitisMedia: `/form/${FORM_UUID_EARACHE}`,
  formUrinaryTractInfection: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}`,
};

export const shinglesRoutes = {
  assessment: `/form/${FORM_UUID_SHINGLES}/assessment`,
  generalQualifier: `/form/${FORM_UUID_SHINGLES}/general-qualifier`,
  prescreening: `/form/${FORM_UUID_SHINGLES}/prescreening`,
  photoUpload: `/form/${FORM_UUID_SHINGLES}/photo-upload`,
  secondaryScreening: `/form/${FORM_UUID_SHINGLES}/secondary-screening`,
  disqualification: `/form/${FORM_UUID_SHINGLES}/disqualification`,
  confirmation: `/form/${FORM_UUID_SHINGLES}/confirmation`,
};

export const impetigoRoutes = {
  assessment: `/form/${FORM_UUID_IMPETIGO}/assessment`,
  generalQualifier: `/form/${FORM_UUID_IMPETIGO}/general-qualifier`,
  prescreening: `/form/${FORM_UUID_IMPETIGO}/prescreening`,
  photoUpload: `/form/${FORM_UUID_IMPETIGO}/photo-upload`,
  secondaryScreening: `/form/${FORM_UUID_IMPETIGO}/secondary-screening`,
  disqualification: `/form/${FORM_UUID_IMPETIGO}/disqualification`,
  confirmation: `/form/${FORM_UUID_IMPETIGO}/confirmation`,
};

export const infectedInsectBitesRoutes = {
  assessment: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/assessment`,
  secondaryAssessment: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/secondary-assessment`,
  generalQualifier: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/general-qualifier`,
  prescreening: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/prescreening`,
  photoUpload: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/photo-upload`,
  secondaryScreening: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/secondary-screening`,
  disqualification: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/disqualification`,
  confirmation: `/form/${FORM_UUID_INFECTED_INSECT_BITES}/confirmation`,
};

export const acuteSoreThroatRoutes = {
  assessment: `/form/${FORM_UUID_SORE_THROAT}/assessment`,
  secondaryAssessment: `/form/${FORM_UUID_SORE_THROAT}/secondary-assessment`,
  thirdAssessment: `/form/${FORM_UUID_SORE_THROAT}/third-assessment`,
  fourthAssessment: `/form/${FORM_UUID_SORE_THROAT}/fourth-assessment`,
  fifthAssessment: `/form/${FORM_UUID_SORE_THROAT}/fifth-assessment`,
  generalQualifier: `/form/${FORM_UUID_SORE_THROAT}/general-qualifier`,
  prescreening: `/form/${FORM_UUID_SORE_THROAT}/prescreening`,
  photoUpload: `/form/${FORM_UUID_SORE_THROAT}/photo-upload`,
  secondaryScreening: `/form/${FORM_UUID_SORE_THROAT}/secondary-screening`,
  disqualification: `/form/${FORM_UUID_SORE_THROAT}/disqualification`,
  confirmation: `/form/${FORM_UUID_SORE_THROAT}/confirmation`,
};

export const acuteSinusitisRoutes = {
  prescreening: `/form/${FORM_UUID_SINUS_INFECTION}/prescreening`,
  generalQualifier: `/form/${FORM_UUID_SINUS_INFECTION}/general-qualifier`,
  assessment: `/form/${FORM_UUID_SINUS_INFECTION}/assessment`,
  secondaryAssessment: `/form/${FORM_UUID_SINUS_INFECTION}/secondary-assessment`,
  thirdAssessment: `/form/${FORM_UUID_SINUS_INFECTION}/third-assessment`,
  fourthAssessment: `/form/${FORM_UUID_SINUS_INFECTION}/fourth-assessment`,
  fifthAssessment: `/form/${FORM_UUID_SINUS_INFECTION}/fifth-assessment`,
  additionalAssessment: `/form/${FORM_UUID_SINUS_INFECTION}/additional-assessment`,
  disqualification: `/form/${FORM_UUID_SINUS_INFECTION}/disqualification`,
  confirmation: `/form/${FORM_UUID_SINUS_INFECTION}/confirmation`,
};

export const acuteOtitisMediaRoutes = {
  prescreening: `/form/${FORM_UUID_EARACHE}/prescreening`,
  generalQualifier: `/form/${FORM_UUID_EARACHE}/general-qualifier`,
  secondaryScreening: `/form/${FORM_UUID_EARACHE}/secondary-screening`,
  assessment: `/form/${FORM_UUID_EARACHE}/assessment`,
  secondaryAssessment: `/form/${FORM_UUID_EARACHE}/secondary-assessment`,
  thirdAssessment: `/form/${FORM_UUID_EARACHE}/third-assessment`,
  disqualification: `/form/${FORM_UUID_EARACHE}/disqualification`,
  confirmation: `/form/${FORM_UUID_EARACHE}/confirmation`,
};

export const urinaryTractInfectionRoutes = {
  prescreening: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/prescreening`,
  generalQualifier: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/general-qualifier`,
  secondaryScreening: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/secondary-screening`,
  assessment: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/assessment`,
  secondaryAssessment: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/secondary-assessment`,
  thirdAssessment: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/third-assessment`,
  disqualification: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/disqualification`,
  confirmation: `/form/${FORM_UUID_URINARY_TRACT_INFECTION}/confirmation`,
};

export default r;
