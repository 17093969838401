import { AnyAction } from 'redux';

import { ELanguage } from 'interfaces/common';
import * as ActionTypes from 'actions/app.actions';
import * as FormActionTypes from 'modules/forms/actions/form.actions';
import { IBranding } from 'interfaces/branding.interfaces';
import { TLocationShort } from 'interfaces/locations.interfaces';
import { defaultLocale } from 'constants/app.constants';

export interface IAppStore {
  language: ELanguage;
  location: TLocationShort | undefined;
  branding: IBranding | null;
  formToken: string;
  loading: {
    initialize: boolean;
    getPermissions: boolean;
    branding: boolean;
  };
  errors: {
    initialize: unknown;
    getPermissions: unknown;
    branding: unknown;
  };
}

const initialState: IAppStore = {
  language: defaultLocale,
  location: undefined,
  branding: null,
  formToken: '',
  loading: {
    initialize: true,
    getPermissions: true,
    branding: true,
  },
  errors: {
    initialize: null,
    getPermissions: null,
    branding: null,
  },
};

const appReducer = (state = initialState, action: AnyAction): IAppStore => {
  switch (action.type) {
    case ActionTypes.initializeStart.type: {
      return { ...state, loading: { ...state.loading, initialize: true } };
    }
    case ActionTypes.initializeStop.type: {
      return { ...state, loading: { ...state.loading, initialize: false } };
    }
    case ActionTypes.changeLanguage.type:
      return {
        ...state,
        language: action.payload,
        loading: { ...state.loading, initialize: true },
      };
    case ActionTypes.changeLocation.type:
      return {
        ...state,
        location: action.payload,
      };
    case FormActionTypes.setFormToken.type:
      return {
        ...state,
        formToken: action.payload,
      };

    case ActionTypes.getPermission.type:
      return {
        ...state,
        loading: { ...state.loading, getPermissions: true },
        errors: { ...state.errors, getPermissions: null },
      };
    case ActionTypes.getPermissionSuccess.type:
      return {
        ...state,
        loading: { ...state.loading, getPermissions: false },
        errors: { ...state.errors, getPermissions: null },
      };
    case ActionTypes.getPermissionError.type: {
      return {
        ...state,
        loading: { ...state.loading, getPermissions: false },
        errors: { ...state.errors, getPermissions: action.payload || null },
      };
    }

    case ActionTypes.getDomainBranding.type:
      return {
        ...state,
        loading: { ...state.loading, branding: true },
        errors: { ...state.errors, branding: null },
      };
    case ActionTypes.getDomainBrandingSuccess.type:
      return {
        ...state,
        branding: action.payload,
        loading: { ...state.loading, branding: false },
        errors: { ...state.errors, branding: null },
      };
    case ActionTypes.getDomainBrandingError.type: {
      return {
        ...state,
        loading: { ...state.loading, branding: false },
        errors: { ...state.errors, branding: action.payload || null },
      };
    }
    case ActionTypes.getOrganizationBrandingSuccess.type: {
      const branding = action.payload as IBranding;
      return {
        ...state,
        branding,
        loading: { ...state.loading, branding: false },
        errors: { ...state.errors, branding: null },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
