import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import moment from 'moment';

import * as api from 'modules/forms/api/forms.api';
import * as ActionTypes from 'modules/forms/actions/form.actions';
import { IAppointment, IAppointmentForm } from 'modules/forms/interfaces/form.interfaces';
import {
  ActionTypePayload,
  EWebviewMessageType,
  IRequestReturnType,
  ISagaResponse,
} from 'interfaces/common';
import {
  shinglesRoutes,
  impetigoRoutes,
  infectedInsectBitesRoutes,
  acuteSoreThroatRoutes,
  acuteSinusitisRoutes,
  acuteOtitisMediaRoutes,
  urinaryTractInfectionRoutes,
} from 'modules/forms/constants/routes.constants';
import {
  forms,
  FORM_UUID_IMPETIGO,
  FORM_UUID_SHINGLES,
  FORM_UUID_INFECTED_INSECT_BITES,
  FORM_UUID_SORE_THROAT,
  FORM_UUID_SINUS_INFECTION,
  FORM_UUID_EARACHE,
  FORM_UUID_URINARY_TRACT_INFECTION,
  DAY_TIME_NO_TIMEZONE_FORMAT,
  EMonitoringEvent,
  DOB_FORMAT,
  DATE_SERVER_FORMAT,
} from 'constants/app.constants';
import MonitoringService from 'services/MonitoringService';
import { serverError } from 'helpers/serverError.helper';
import toastEmit from 'helpers/toast.helpers';
import { ETypeToast } from 'interfaces/toast.interfaces';
import { IStore } from 'config/reducers';

function* getFormAppointment({ payload }: ActionTypePayload<string>) {
  try {
    const { success, results }: ISagaResponse<IAppointment> = yield call(
      api.getFormAppointment,
      payload,
    );
    if (success) {
      yield put(ActionTypes.getFormAppointmentSuccess(results));
      if (results?.service?.formUuid) {
        yield put(ActionTypes.setCurrentForm(results?.service?.formUuid));
      }
    }
  } catch (error) {
    const { message } = error as IRequestReturnType;
    serverError(error);
    yield put(ActionTypes.getFormAppointmentError(message));
  }
}

function* setCurrentForm({ payload }: ActionTypePayload<string>) {
  yield put(
    ActionTypes.setFormUuid({
      uuid: payload,
      title: forms.find(item => item.uuid === payload)?.title || '',
    }),
  );
  switch (payload) {
    case FORM_UUID_SHINGLES:
      yield put(replace({ pathname: shinglesRoutes.prescreening, search: window.location.search }));
      break;
    case FORM_UUID_IMPETIGO:
      yield put(replace({ pathname: impetigoRoutes.prescreening, search: window.location.search }));
      break;
    case FORM_UUID_INFECTED_INSECT_BITES:
      yield put(
        replace({
          pathname: infectedInsectBitesRoutes.prescreening,
          search: window.location.search,
        }),
      );
      break;
    case FORM_UUID_SORE_THROAT:
      yield put(
        replace({
          pathname: acuteSoreThroatRoutes.prescreening,
          search: window.location.search,
        }),
      );
      break;
    case FORM_UUID_SINUS_INFECTION:
      yield put(
        replace({
          pathname: acuteSinusitisRoutes.prescreening,
          search: window.location.search,
        }),
      );
      break;
    case FORM_UUID_EARACHE:
      yield put(
        replace({
          pathname: acuteOtitisMediaRoutes.prescreening,
          search: window.location.search,
        }),
      );
      break;
    case FORM_UUID_URINARY_TRACT_INFECTION:
      yield put(
        replace({
          pathname: urinaryTractInfectionRoutes.prescreening,
          search: window.location.search,
        }),
      );
      break;
    default:
      break;
  }
}

function* postAppointmentForm({
  payload: { form, photos, route },
}: ActionTypePayload<{ form: Partial<IAppointmentForm>; photos?: File[]; route?: string }>) {
  try {
    const {
      auth: { guest, patient },
      form: { formUuid },
      app: { location },
    }: IStore = yield select();
    const data = { ...form };
    if (guest) {
      data.locationUuid = location?.uuid;
      data.formUuid = formUuid;
      data.guestDto = {
        ...guest,
        dob: moment(guest.dob, DOB_FORMAT).format(DATE_SERVER_FORMAT),
        continueAsGuest: true,
        agreedTerms: true,
        agreedTermsDate: moment().utc().format(DAY_TIME_NO_TIMEZONE_FORMAT),
        locationUuid: location?.uuid,
      };
    }
    if (patient) {
      data.locationUuid = location?.uuid;
      data.formUuid = formUuid;
      data.customerUuid = patient?.customerUuid;
    }
    const { response, ...logData } = data;
    MonitoringService.logEvent(EMonitoringEvent.POST_APPOINTMENT_FORM, { ...logData });

    const { success, results } = yield call(api.postAppointmentForm, data, photos);
    if (success) {
      yield put(ActionTypes.postAppointmentFormSuccess(results));
      if (route) {
        yield put(replace({ pathname: route, search: window.location.search }));
      }
    }
  } catch (error) {
    const { message, codes } = error as IRequestReturnType;
    MonitoringService.logEvent(EMonitoringEvent.POST_APPOINTMENT_FORM_ERROR, { error });
    switch (codes?.[0]) {
      case 'already_exists': {
        toastEmit({
          type: ETypeToast.error,
          title: 'The form has already been submitted',
        });
        break;
      }
      case 'date_out_of_range': {
        toastEmit({
          type: ETypeToast.error,
          title: 'The service is not available right now',
        });
        break;
      }
      default:
        toastEmit({
          type: ETypeToast.error,
          title: message,
        });
        break;
    }
    yield put(ActionTypes.postAppointmentFormError(message));
  }
}

function* closeForm() {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.ReactNativeWebView) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: EWebviewMessageType.CLOSE,
        }),
      );
    }
  } catch (error) {
    serverError(error);
  }
}

export default function* formSagas(): Generator {
  yield all([
    takeEvery(ActionTypes.closeForm.type, closeForm),
    takeEvery(ActionTypes.getFormAppointment.type, getFormAppointment),
    takeEvery(ActionTypes.postAppointmentForm.type, postAppointmentForm),
    takeEvery(ActionTypes.setCurrentForm.type, setCurrentForm),
  ]);
}
