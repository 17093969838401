import React from 'react';
import Select, {
  components,
  ControlProps,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  ValueContainerProps,
  NoticeProps,
  InputProps,
  GroupBase,
  OptionProps,
  MultiValueProps,
} from 'react-select';
import { AsyncPaginate, reduceGroupedOptions } from 'react-select-async-paginate';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { OptionsOrGroups } from 'react-select/dist/declarations/src/types';
import classNames from 'classnames';
import intl from 'react-intl-universal';

import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import { IQueryCommon } from 'interfaces/common';
import { loadOptions } from './loadOptions';

export function indicatorSeparator<T>(
  rest: IndicatorSeparatorProps<T>,
  contentSeparator?: JSX.Element,
): JSX.Element {
  if (contentSeparator) {
    return contentSeparator;
  }
  return <components.IndicatorSeparator {...rest} />;
}

export function DropdownIndicator<T>(rest: DropdownIndicatorProps<T>): JSX.Element {
  return (
    <components.DropdownIndicator {...rest}>
      <Icon iconName="caret-down" />
    </components.DropdownIndicator>
  );
}

export function valueContainer<T>(
  { children, ...props }: ValueContainerProps<T>,
  placeholder?: string,
): JSX.Element {
  return (
    <components.ValueContainer
      {...props}
      className={classNames({
        'form-custom-select__value-container-placeholder-top':
          ((!!props.selectProps.value && !!Object.values(props.selectProps.value).length) ||
            props.selectProps.inputValue) &&
          !!placeholder,
      })}
    >
      <span className="form-custom-select__value-container-placeholder">{placeholder}</span>
      {children}
    </components.ValueContainer>
  );
}

export function control<T>(
  { children, ...props }: ControlProps<T>,
  size?: 'small' | 'large',
  isSearchable?: boolean,
  noBorder?: boolean,
  prepend?: JSX.Element,
): JSX.Element {
  return (
    <components.Control
      {...props}
      className={classNames({
        'form-custom-select__control--small': size === 'small',
        'form-custom-select__control--pointer': !isSearchable,
        'form-custom-select__control--without-border': noBorder,
      })}
    >
      {!!prepend && <div className="form-custom-select__control--prepend">{prepend}</div>}
      {children}
    </components.Control>
  );
}

export function NoOptionsMessage<T>(props: NoticeProps<T>): JSX.Element {
  return (
    <components.NoOptionsMessage {...props}>
      {intl.get(`common.noOptions`)}
    </components.NoOptionsMessage>
  );
}

export function input<T>(props: InputProps<T>, placeholder?: string): JSX.Element {
  return <components.Input {...props} placeholder={placeholder || ''} />;
}

export function LoadingMessage<T>(props: NoticeProps<T>): JSX.Element {
  return (
    <components.LoadingMessage {...props}>{intl.get(`common.loading`)}</components.LoadingMessage>
  );
}

export function LoadingIndicator(): JSX.Element {
  return <Spinner className="form-custom-select__loading-indicator--spinner" size="sm" />;
}

export function option<T>(
  { children, ...rest }: OptionProps<T>,
  isNeedCheck?: (data: T) => boolean,
): JSX.Element {
  return (
    <components.Option {...rest}>
      {isNeedCheck ? (
        <div className="form-custom-select__option--justify-center">
          {children}
          {isNeedCheck(rest.data) && (
            <Icon iconName="check-circle" className="form-custom-select__option--check" />
          )}
        </div>
      ) : (
        children
      )}
    </components.Option>
  );
}

export function multiValue<T>(
  { children, className, ...rest }: MultiValueProps<T>,
  multiValueWithoutBg?: boolean,
): JSX.Element {
  return (
    <components.MultiValue
      {...rest}
      className={classNames(className, {
        'form-custom-select__multi-value--without-bg': multiValueWithoutBg,
      })}
    >
      {children}
    </components.MultiValue>
  );
}

interface IReturnSelect<T> {
  urlRequest?: string;
  block?: boolean;
  isAsync?: boolean;
  isGroupLabel?: boolean;
  query?: IQueryCommon;
  selectProps: StateManagerProps<T>;
  options?: T[] | OptionsOrGroups<T, GroupBase<T>>;
}
export function ReturnSelect<T>({
  block,
  isAsync,
  isGroupLabel = false,
  query,
  selectProps,
  urlRequest,
  options,
}: IReturnSelect<T>): JSX.Element {
  return (
    <div
      className={classNames({
        'form-block-custom-select-wrap': block,
        'form-inline-custom-select-wrap': !block,
      })}
    >
      {isAsync ? (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <AsyncPaginate<T, GroupBase<T>, any, boolean>
          {...selectProps}
          loadOptions={async (search, loadedOptions, { page }) =>
            loadOptions<T>(search, loadedOptions, page, isGroupLabel, urlRequest, query)
          }
          debounceTimeout={500}
          additional={{
            page: 0,
          }}
          reduceOptions={isGroupLabel ? reduceGroupedOptions : undefined}
        />
      ) : (
        <Select<T, boolean, GroupBase<T>> {...selectProps} options={options || []} />
      )}
    </div>
  );
}
